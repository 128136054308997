@import 'styles/_breakpoints.scss';
@import 'styles/_mixin.scss';

.container {
  width: 100%;
  position: relative;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  padding: 10px;
  height: 36px;
  background-color: transparent;
  width: 100%;
}

.label {
  color: #2a2e34;
  font-size: 14px;
  font-weight: 500;
  margin-left: 3px;
  margin-bottom: 5px;
}
.icon {
  cursor: pointer;
  pointer-events: none;
  .img {
    width: 16px;
    height: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
