.container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #757575;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggleSwitch {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 40px; 
  border-radius: 25px; 
  background-color: #d9d9d9;
  transition: background-color 0.3s ease;
}

.container input:checked ~ .toggleSwitch {
  background-color: rgb(42, 46, 52);
}

.toggleSwitch:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 16px; 
  height: 16px; 
  border-radius: 50%;
  background-color: white;
  transition: left 0.3s ease;
}

.container input:checked ~ .toggleSwitch:after {
  left: calc(100% - 18px);
}
