.loader {
  width: 40px;
  height: 40px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #000;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  -webkit-animation: spin 0.65s linear infinite;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}
