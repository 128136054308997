@import 'styles/breakpoints';
@import 'styles/mixin';

.inputContainer {
  width: 100%;
  // margin-bottom: 20px;
  position: relative;

  label {
    color: #2a2e34;
    font-size: 14px;
    font-weight: 500;
    margin-left: 3px;
    margin-bottom: 5px;
  }

  .inputWrapper {
    // margin-bottom: -10px;
  }

  .input {
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    padding: 10px;
    height: 36px;
    background-color: transparent;
    width: 100%;
    color: #2a2e34;
    font-size: 16px;
    font-weight: 400;
    box-shadow: none;
    &::placeholder {
      color: #8b909a;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .date {
    position: absolute;
    right: 12px;
    top: 11px;
  }
  .date1 {
    position: absolute;
    left: 12px;
    top: 10px;
  }

  .errorMessage {
    position: absolute;
    display: block;
    font-size: 11px;
    color: #ff5050;
    top: 100%;
  }
}
