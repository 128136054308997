@import 'styles/breakpoints';
@import 'styles/mixin';

.modalWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 25000;
  padding: 20px 20px;
  backdrop-filter: blur(3px);
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  .modalContentWrapper {
    position: relative;
    overflow-y: auto;
    max-width: 800px;
    width: 100%;
    max-height: 90vh;
    z-index: 250000;
    overflow-y: auto;
    background: #ffffff;
    border-radius: 8px;
    padding: 39px 36px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    &::-webkit-scrollbar:vertical {
      display: none;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebebeb;

      p {
        font-weight: 600 !important;
        font-size: 25px !important;
        color: #252525;
        width: 100% !important;
        text-align: left !important;
        margin-top: 0px !important;
        padding-bottom: 15px;
      }
    }
  }
}
