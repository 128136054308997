@import 'styles/breakpoints';
@import 'styles/mixin';

.tabs {
  border-radius: 5px;
  border: 1px solid #2a2e34;
  display: flex;
  width: max-content;
  p {
    color: #000;
    padding: 10px;
    background-color: transparent;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    &:first-child {
      border-radius: 3px 0px 0px 3px;
    }
    &:last-child {
      border-radius: 0px 3px 3px 0px;
    }
  }
}
