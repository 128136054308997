@import 'styles/breakpoints';
@import 'styles/mixin';

.main {
  h6 {
    color: var(--Text-Color-Dark, #2a2e34);
    font-size: 13px;
    font-weight: 600;
  }
  p {
    color: var(--Text-Color-Dark, #2a2e34);
    font-size: 14px;
    font-weight: 400;
  }
  .img {
    max-width: 340px;
    border-radius: 10px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  h1 {
    color: var(--Text-Color-Dark, #2a2e34);
    font-size: 18px;
    font-weight: 500;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
  }
  .right {
    flex: 1;

    .grid {
      @include gridTwo(10, 10);
      margin-top: 20px;
      align-items: center;
    }
  }
  .location {
    margin-top: 10px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
  overflow-y: overlay;
  overflow-x: hidden;

}