@import 'styles/breakpoints';
@import 'styles/mixin';

.main {
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      gap: 10px;
      .filter {
        border-radius: 6px;
        border: 1px solid #d6d6d6;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 36px;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
        p {
          color: #2a2e34;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-right: 15px;
      .sort {
        color: #2a2e34;
        font-size: 13px;
        font-weight: 500;
      }
      .ascending {
        display: flex;
        flex-direction: column;
        .svg {
          border-radius: 5px;
          border: 1px solid #d6d6d6;
          height: 15px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            width: 10.158px;
            height: 5px;
          }
        }
      }
      .img {
        width: 3.697px;
        height: 14.563px;
        cursor: pointer;
      }
    }
  }
}
.title {
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    min-width: 36px;
    height: 24px;
    border-radius: 2px;
  }
}
.position {
  position: relative;
  img {
    cursor: pointer;
  }
  .backdrop {
    position: fixed;
    inset: 0;
    // background-color: rgba(0, 0, 0, 0.6);
    background-color: transparent;
    z-index: 2;
  }

  .menu {
    position: absolute;
    width: 200px;
    border-radius: var(--Input-Radius, 5px);
    background: var(--Text-Color-White, #fff);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    top: 30px;
    left: -30px;
    z-index: 3;
    p {
      color: var(--Text-Color-Dark, #2a2e34);
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      border-bottom: 1px solid #d6d6d6;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.bodyClass {
  height: calc(100vh - 235px);
}

.toggle {
  display: flex;
  justify-content: space-between;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  font-size: 12px;
  font-weight: 400;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 160%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
