@import 'styles/breakpoints';
@import 'styles/mixin';

.mainClass {
  .h1 {
    color: #2a2e34;
    font-size: 22px;
    font-weight: 500;
    margin-top: 10px;
    margin-left: 5px;
  }
  .tabClass {
    margin-top: 20px;
  }
}
