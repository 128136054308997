@import 'styles/breakpoints';
@import 'styles/mixin';

.sidebar {
  border-radius: 10px;
  background: #2a2e34;
  display: flex;
  width: 300px;
  padding: 20px 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - 24px);
  .top {
    width: 100%;
    .logo {
      width: 266px;
      height: 87.783px;
    }
    .border {
      border-bottom: 1px solid #d9d9d9;
      padding-top: 20px;
    }
    .userDiv {
      display: flex;
      align-items: center;
      gap: 16px;
      padding-bottom: 20px;
      margin-top: 20px;
      img {
        width: 35px;
        height: 35px;
      }
      h6 {
        color: #fff;
        font-size: 24px;
        font-weight: 600;
      }
      p {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .link {
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    cursor: pointer;
    img {
      width: 20.28px;
      height: 20.28px;
    }
    p {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
