@import 'styles/breakpoints.scss';
@import 'styles/mixin.scss';

.btn {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 10px;
  gap: 8.971px;
  border-radius: 5px;
  background-color: #2a2e34;

  &:disabled {
    background-color: #262626;
    span {
      color: #383838 !important;
    }
  }
  .loaderClass {
    width: 19px;
    height: 19px;
    border-color: rgb(255, 255, 255);
    border-right: 2px solid transparent;
    margin: auto;
  }

  .btnTitle {
    color: #fff;
    font-size: 13px;
    font-weight: 600;
  }
}
