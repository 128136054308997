.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 25px);
  width: 100%;
  .innerDiv {
    max-width: 510px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    .img {
      max-width: 372.555px;
      min-height: 80px;
    }
    .card {
      border-radius: 12.722px;
      background: #fff;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
      padding: 30px;
      width: 100%;
      h1 {
        color: #2a2e34;
        text-align: center;
        font-size: 33.076px;
        font-weight: 700;
      }
      .p {
        color: #2a2e34;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 45px;
        text-align: center;
      }
      .span {
        color: #2a2e34;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
      }
      .input {
        margin-bottom: 25px;
      }
    }
  }
}
