@import 'styles/breakpoints';
@import 'styles/mixin';

.tag {
  border-radius: 3px;
  background: var(--Green, #57b894);
  display: flex;
  padding: 3px 5px;
  justify-content: center;
  align-items: center;
  width: max-content;
  p {
    color: #2a2e34;
    font-size: 14px;
    font-weight: 400;
  }
}
