@import 'styles/breakpoints';
@import 'styles/mixin';

.modal {
  max-width: 370px !important;
  .img {
    display: flex;
    justify-content: center;
    img {
      width: 100px;
      height: 100px;
    }
  }
  p {
    color: var(--Text-Color-Dark, #2a2e34);
    text-align: center;
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 400;
  }
  .btnDiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    button {
      max-width: 100%;
    }
    .btn {
      border: 1px solid var(--Primary-Dark, #2a2e34);
      background-color: transparent;
      span {
        color: #2a2e34;
      }
    }
  }
}
