@import 'styles/breakpoints';
@import 'styles/mixin';

.filter {
  background: var(--Text-Color-White, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  height: 100vh;
  max-width: 520px;
  right: 0px;
  z-index: 3;
  width: 100%;
  top: 0;
  border-radius: 20px 0px 0px 20px;
  .filterHeader {
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    position: sticky;
    background-color: #fff;
    z-index: 3;
    border-radius: 20px 0px 0px 0px;

    h1 {
      color: #2a2e34;
      font-size: 24px;
      font-weight: 600;
    }
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
  .body {
    padding: 20px;
    height: calc(100vh - 56px);
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .inner {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .label {
        label {
          color: #2a2e34;
          font-size: 14px;
          font-weight: 500;
          margin-left: 3px;
          margin-bottom: 5px;
        }
        .flex {
          display: flex;
          align-items: center;
          gap: 10px;
          .border {
            border-bottom: 1px solid #d6d6d6;
            width: 20px;
          }
        }
      }
    }
    .btnDiv {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      button {
        max-width: 80px;
      }
      .btn {
        border: 1px solid var(--Primary-Dark, #2a2e34);
        background-color: transparent;
        span {
          color: #2a2e34;
        }
      }
    }
  }
}

.loaderClass {
  border-color: var(--Primary-Dark, #2a2e34) !important;
  border-right: 2px solid #fff !important;
}

.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}
