@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
html,
body {
  margin: 0px !important;
  padding: 0px;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

body {
  padding: 12px 10px !important;
}
input[type='date' i] {
  font-size: 14px !important;
}

::-webkit-scrollbar {
  display: none;
}
