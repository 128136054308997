@import 'styles/_breakpoints.scss';
@import 'styles/_mixin.scss';

.tableWrapper {
  overflow-y: hidden;
  overflow-x: overlay;
  padding-bottom: 10px;

  .table {
    flex: 1;
    overflow: hidden;
    text-transform: capitalize;
    border-collapse: collapse;
    width: 100%;
    a {
      text-decoration: none;
      color: #2a2e34;
    }

    .thead {
      z-index: 2;
      position: sticky;
      top: 0px;
      border-bottom: 1.5px solid #2a2e34;
      background: #2a2e34;

      .heading {
        padding: 10px;
        .headingTitle {
          color: #ffff;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.tr {
  height: 100%;

  &:first-child {
    margin-top: 0px;
  }
}
.fix {
  border-bottom: 0.5px solid #2a2e34;
  border-left: 1px solid #2a2e34;
  padding: 9px 10px;
  &:last-child {
    border-right: 1px solid #2a2e34;
  }
}
.td {
  color: #2a2e34;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.mainHeight {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  &:last-child {
    padding-bottom: 75px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noData {
  color: #2a2e34;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 20px;
}
