@import 'styles/breakpoints';
@import 'styles/mixin';

.layout {
  display: flex;
  gap: 20px;
}
.heightDiv {
  height: calc(100vh - 24px);
  overflow-y: auto;
  flex: 1;
}
